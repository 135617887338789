import React from 'react';
//import Button from '@material-ui/core/Button';
import {
    Button,
    DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Typography, Container, Grid
} from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const topfill = {
    height: "99%",
    paddingTop: 30,
    backgroundColor: "#fff"
};

const PassObj = {};
PassObj.icon = 'https://comunidad.olinca.edu.mx/com/shared/img/menu/BKicon_2.png';
PassObj.user = "";

class SetPass extends React.Component {

    constructor() {
        super();
        this.state = {
            correos: [],
            id: [],
            llave: []
        };
        this.ponestado = this.ponestado.bind(this);
        //this.child = this.child.bind(this);
    }

    ponestado(data, hashes, ids) {
        //alert("1 Desde SetState " + this.state.correos);
        this.setState({ correos: data, id: ids, llave: hashes });
        //alert("2 Desde SetState " + this.state.correos[0]);
    }

    render() {

        return (
            <Router >
                <Switch>
                    <Route path="/confirmacion">
                        <Confirmacion></Confirmacion>
                    </Route>
                    <Route path="/:id/:tokenw/:tokens/:nombre" component={PaginaInicial} ></Route>
                    <Route path="/">
                        <NoParam />
                    </Route>
                </Switch>
            </Router>
        );
    }



}

class NoParam extends React.Component {
    render() {
        return (
            <div>
                ERROR
            </div>
        );
    }
}

class PaginaInicial extends React.Component {

    constructor(props) {
        super(props);
        this.alertclose = this.alertclose.bind(this);
    }

    /* Estados posibles: inicial , iraraiz , iraconfirmacion */
    state = {
        pass1: '',
        pass2: '',
        /*enviado: false,*/
        alerta_open: false,
        alerta_mensaje: "",
        tipo: "password",
        estado: "inicial"
    }

    validatoken() {
        var md5 = require('md5');
        var tokenlocal = md5(this.props.match.params.id + this.props.match.params.tokens);
        if (tokenlocal === this.props.match.params.tokenw) {
            //if(this.props.match.params.tokens === this.props.match.params.tokenw){
            //return true;
        } else {
            //return false;
            this.setState({ estado: "iraraiz" });
        }

    }

    handleChange = (event) => {
        const pass1 = event.target.value.trim();
        this.setState({ pass1 });
    }

    mostrarocultarpass() {
        //alert(this.state.tipo);
        if (this.state.tipo === "password") {
            this.setState({ tipo: "text" })
        } else {
            this.setState({ tipo: "password" })
        }
        //alert(this.state.tipo);
    }

    alertclose() {
        this.setState({ alerta_open: false, alerta_mensaje: "" });
    }

    handleSubmit = () => {
        // your submit logic
        //alert("enviando " + this.state.pass1);   // this.props.match.params.id + this.props.match.params.tokens
        fetch('https://comunidad.olinca.edu.mx/app/pass/asavepass.php?p=' + this.state.pass1 + '&i=' + this.props.match.params.id + '&t=' + this.props.match.params.tokens)
            .then(res => res.json())
            .then((data) => {
                //this.setState({ contacts: data })
                //alert(data.exito);
                console.log('?p=' + this.state.pass1 + '&i=' + this.props.match.params.id + '&t=' + this.props.match.params.tokens);

                if (data.exito === 1) {
                    //decirle al usuario que ya tenemos su mensaje
                    //props.stateHandler(data.correos, data.hashes, data.ok);
                    //history.push("/selectemail");
                    //alert("recibido, revise su buzon de correo")
                    //this.setState({ enviado: true });
                    PassObj.user = data.username;
                    this.setState({ estado: "iraconfirmacion" });
                    //this.forceUpdate();
                } else if (data.exito === 0) {
                    //alert(data.error);
                    this.setState({ alerta_open: true, alerta_mensaje: data.error });
                } else {
                    alert("Error de comunicación");
                    this.setState({ alerta_open: true, alerta_mensaje: "Error de comunicación" });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                this.setState({ alerta_open: true, alerta_mensaje: "Error de comunicación, por favor intente más tarde" });
            })


    }

    render() {
        const { pass1 } = this.state;
        this.validatoken();
        var ojo = <VisibilityIcon />;

        if (this.state.tipo === "password") {
            ojo = <VisibilityIcon />;
        } else {
            ojo = <VisibilityOffIcon />;
        }


        if (this.state.estado === "iraraiz") {
            return (
                <Redirect to="/"></Redirect>
            );
        } else if (this.state.estado === "iraconfirmacion") {
            return (
                <Redirect to="/confirmacion"></Redirect>
            );
        } else return (
            <div>

                <Grid container spacing={0}
                    direction="column"
                    alignItems="center"
                    
                    justify="flex-start" style={{ minHeight: '97vh' }}>
                    <Grid item xs={10} >
                        <div style={topfill}></div>
                    </Grid>

                    <Grid item xs={8}>
                        <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
                            <div>
                                <img src={PassObj.icon} alt="ICONO"></img>
                            </div>
                            <div>
                                Asignación de nueva contraseña
                            </div>
                        </Typography>
                        <Typography variant="h7" align="center" color="textSecondary" paragraph>
                            {this.props.match.params.nombre}
                        </Typography>
                    </Grid>
                    <Grid item xs={11}>

                        <Typography variant="body1" align="center" color="textSecondary" paragraph>
                            Por favor ingrese su nueva contraseña, al menos 8 carácteres que incluyan una mayúscula y un número.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>

                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={errors => console.log(errors)}
                        >
                            <Grid item xs={12} >

                                <TextValidator
                                    label="Nueva Contraseña"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    type={this.state.tipo}
                                    name="pass1"
                                    autoComplete='off'
                                    value={pass1}
                                    validators={['required', 'matchRegexp:^[A-Za-z0-9\\.\\$\#\%\(\)\=\!\-\:\;\+\*]{8,32}$']}
                                    errorMessages={['campo requerido', 'Al menos 8 carácteres']}
                                    style={{ verticalAlign: 'sub' }}
                                /><Button onClick={() => { this.mostrarocultarpass(); }} >{ojo}</Button></Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" endIcon={<ArrowForwardIcon />}>Guardar contraseña</Button>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                    <MiAlerta isOpen={this.state.alerta_open} mensaje={this.state.alerta_mensaje} cerrador={this.alertclose}></MiAlerta>


                </Grid>

            </div>
        );
    }
}


class Confirmacion extends React.Component {
    render() {
        return (
            <div>
                <Grid container spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center" style={{ minHeight: '75vh' }}>
                    <Grid item xs={6}>

                        <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
                            <div>
                                <img src={PassObj.icon} alt="ICONO"></img>
                            </div>
                            <div>
                                Contraseña actualizada
                            </div>
                        </Typography>
                        <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                            <div>
                                Usuario: {PassObj.user}
                            </div>
                        </Typography>
                        <Typography variant="body1" align="center" color="textSecondary" paragraph>
                            Se ha completado su actualización de contraseña. Utilice el password definido y el nombre de usuario indicado arriba.
                        </Typography>

                    </Grid>
                </Grid>
            </div>
        );
    }
}




class MiAlerta extends React.Component {

    state = {
        open: false,
        mensaje: ""
    };

    /*handleClose = () => {
        this.setState({ open: false });
    };

    setOpen(texto) {
        this.setState({ open: true, mensaje: texto });
    }*/

    render() {
        return (

            <Dialog
                open={this.props.isOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Atención"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.mensaje}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.cerrador} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>



        );
    }
}



export default SetPass;




